import Head from 'next/head';

// utils
import { bool } from '../config/env';

export function UsercentricsScripts({ server, globals }) {
  const isPreview = bool(server?.isPreview);
  const ucSettingsId = globals?.uc_settingsId;

  return (
    <Head>
      {/* usercentrics */}
      {Boolean(ucSettingsId) && (
        <>
          <link rel="preconnect" href="https://app.usercentrics.eu" />
          <link rel="preconnect" href="https://api.usercentrics.eu" />
          <link
            rel="preload"
            href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
            as="script"
          />
          <link rel="dns-prefetch" href="https://app.usercentrics.eu" />
          <link rel="dns-prefetch" href="https://api.usercentrics.eu" />
          <script id="testsimon2" />
          <script
            type="application/javascript"
            id="usercentrics-cmp"
            src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
            data-settings-id={ucSettingsId}
            data-version={isPreview ? 'preview' : undefined}
            async
          />
          {/* smart data protector start */}
          {/* <meta data-privacy-proxy-server="https://privacy-proxy-server.usercentrics.eu"></meta>
          <script
          type="application/javascript"
          src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        ></script> */}
          {/* smart data protector end */}
          {/* <script
        id="usercentrics"
        dangerouslySetInnerHTML={{
          __html: `(function () {
                  const customStyle = \`
                  :host(#usercentrics-root) div[data-testid="uc-buttons-container"], :host(#usercentrics-root) div[data-testid="first-line-buttons"] {justify-content: flex-end;}
                  :host(#usercentrics-root) button[data-testid="uc-save-button"] {
                      flex: 0;
                      white-space: pre;
                      cursor: pointer;
                      font-weight: 500;
                      font-family: AktivGrotesk, helvetica, arial, sans-serif;
                      border: 1px solid #000000;
                      line-height: 1.25rem;
                      font-size: 0.875rem;
                      height: 2.5rem;
                      padding: 0.3125rem 1.5rem;
                  }
                  :host(#usercentrics-root) button[data-testid="uc-save-button"]:hover {
                      background-color: rgba(0,0,0,0.08);
                      border: 1px solid #000000;
                  }
                  :host(#usercentrics-root) button[data-testid="uc-save-button"]:active {
                      background-color: var(--gb-brand-primary-light, #000000);
                      border: 1px solid var(--gb-brand-primary-light, #000000);
                      color: #FFFFFF;
                  }
                  :host(#usercentrics-root) button[data-testid="uc-accept-all-button"] {
                      flex: 0;
                      white-space: pre;
                      cursor: pointer;
                      font-weight: 500;
                      font-family: AktivGrotesk, helvetica, arial, sans-serif;
                      background-color: var(--gb-brand-primary-light, #376EB4);
                      color: #ffffff;
                      border: 1px solid var(--gb-brand-primary-light, #0a5a96);
                      line-height: 1.25rem;
                      font-size: 0.875rem;
                      height: 2.5rem;
                      padding: 0.3125rem 1.5rem;
                  }
                  :host(#usercentrics-root) button[data-testid="uc-accept-all-button"]:active, :host(#usercentrics-root) button[data-testid="uc-accept-all-button"]:hover {
                      background-color: var(--gb-brand-primary, #004673);
                      border: 1px solid var(--gb-brand-primary, #004673);
                  }
                  :host(#usercentrics-root) #uc-tab-list {
                      border-bottom: none;
                      padding-bottom: .5em;
                  }
                  :host(#usercentrics-root) #uc-tab-list button {
                      border-bottom-width: 0.25rem;
                      width: auto;
                      border-bottom: 0.25rem solid transparent;
                      height: 2.5rem;
                  }
                  :host(#usercentrics-root) #uc-tab-list button:first-child { margin-right: 1rem }
                  :host(#usercentrics-root) #uc-tab-list button[aria-selected="true"] {
                      border-bottom-color: var(--gb-brand-primary-light, #004673);
                      color: var(--gb-brand-primary-light, #376EB4);
                  }
                  :host(#usercentrics-root) #uc-tab-list button[aria-selected="false"]:hover { border-bottom: 0.25rem solid rgb(180, 180, 180) }
                  :host(#usercentrics-root) div[data-testid="uc-heading-title"] {
                      font-size: 26px;
                      font-weight: var(--uc-heading-title-font-weight, 400) !important;
                      line-height: 30px;
                  }
                  :host(#usercentrics-root) div[data-testid="uc-anchor-link"] {
                      color: var(--gb-brand-primary-light, #376EB4);
                  }
                  :host(#usercentrics-root) div[data-testid="uc-headinger-wrapper"] {
                      padding: 0;
                  }
                  :host(#usercentrics-root) div[data-testid="uc-message-container"] {
                      font-size: 15px;
                      line-height: 24px;
                  }
                  :host(#usercentrics-root) div[data-testid="uc-message-container"] a {
                      color: var(--gb-brand-primary-light, #376EB4);
                  }
                  :host(#usercentrics-root) a, :host(#usercentrics-root), button[data-testid="uc-customize-anchor"] {
                      text-decoration: underline;
                      color: var(--gb-brand-primary-light, #376EB4);
                  }
                  :host(#usercentrics-root) a, :host(#usercentrics-root), button[data-testid="uc-customize-anchor"] span {
                      color: var(--gb-brand-primary-light, #376EB4);
                  }
                  :host(#usercentrics-root) #uc-center-container {
                      margin: 30px auto 0;
                      padding: 1.5rem 0.5rem 1rem 0.5rem;
                      max-width: 750px;
                      width: 75%;
                  }
                  :host(#usercentrics-root) div[data-testid^="uc-tab-"] {
                      height: 2.5rem;
                  }
                  \`;

                  const target = document.querySelector("html");
                  const obsOptions = {
                    childList: true,
                    attributes: false,
                    subtree: true,
                  }
                  let cb = (mutations, observer) => {
                    if (document.querySelector('#usercentrics-root')) {
                      observer.disconnect();
                      const styleElement = document.createElement('style');
                      styleElement.innerHTML = customStyle;
                      try {
                        // chrome
                        document.querySelector('#usercentrics-root').shadowRoot.appendChild(styleElement);
                      } catch (e) { };
                      // add mods to display toggles in correct color if changed
                      try {
                        const sroot = document.querySelector("#usercentrics-root").shadowRoot.querySelector("[data-testid='uc-app-container']");
                        let cb2 = (mutations, observer) => {
                          // try to change colors - do not kill observer in because layer can be opened multiple times
                          try {
                            let paths = sroot.querySelectorAll("button[aria-disabled='false'][aria-checked='true'] svg > g > g > g > path:nth-child(1)");
                            paths.forEach(path => path.setAttribute("fill", "var(--gb-brand-primary-light, #376EB4)"));
                          } catch (e) { }
                          // remove UC Branding
                          try {
                            document.getElementById('usercentrics-root').shadowRoot.querySelector('#uc-center-container a[href*="usercentrics.com"]').closest('div').remove();
                          } catch (e) { }
                        }
                        const obs2 = new MutationObserver(cb2);
                        obs2.observe(sroot, obsOptions);
                      } catch (e) { }
                    }
                  }
                  const obs = new MutationObserver(cb);
                  obs.observe(target, obsOptions);
                })();`,
        }} */}
          {/* /> */}

          {/* usercentrics end */}
          {/* usercentrics privacy proxy */}
          {/* {Boolean(ucSettingsId) && (
        <script
          id="usercentrics-privacy-proxy"
          src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
        />
      )} */}
          {/* usercentrics privacy proxy end */}
        </>
      )}
    </Head>
  );
}
