/* eslint-disable @next/next/no-sync-scripts */
// rule disabled because we need to load the scripts synchronously because of usercentrics
import { useEffect } from 'react';
import { url } from 'config/env';
import Head from 'next/head';

const ciamScriptSrc = (ciam: AppState['globals'][string]['ciam'], locale: string): string => {
  return `${ciam.script_url}?apiKey=${ciam.api_key}&lang=${
    ciam.ciamOverwriteLangCode || locale.split('-')[0]
  }`;
};
const ciamReadyDispatch = () => {
  window.dispatchEvent(new CustomEvent('gigya.session.ready'));
};

interface ScriptsProps {
  globals: LanguageSpecificGlobals;
  server: ServerData;
}

export function Scripts({ globals, server }: Readonly<ScriptsProps>) {
  const appStage = server?.appStage || 'dev';
  const gtmUrl = url(server?.gtmUrl || 'https://serversidegtm.prod.web.geberit.com');

  const gtmKey = globals?.gtmKey;
  const isDev = appStage.includes('dev');
  const isQa = appStage.includes('qa');
  const isProd = !isDev && !isQa;

  // let gtmNoScript = `${gtmUrl}/ns.html?id=${gtmKey}`;
  // if (isDev) {
  //   gtmNoScript = `${gtmUrl}/ns.html?id=${gtmKey}&gtm_auth=Y51geUNuEXhIBsGRexay-w&gtm_preview=env-189&gtm_cookies_win=x`;
  // } else if (isQa) {
  //   gtmNoScript = `${gtmUrl}/ns.html?id='${gtmKey}'&gtm_auth=zoa16TpiGUNPR585n3FnJw&gtm_preview=env-345&gtm_cookies_win=x`;
  // }

  useEffect(() => {
    if (globals?.ciam?.enabled === false) {
      document.querySelector('#gigya-script')?.remove();
    } else if (globals?.ciam?.enabled === true) {
      const script: HTMLScriptElement =
        document.querySelector('#gigya-script') ?? document.createElement('script');
      script.id = 'gigya-script';
      script.src = ciamScriptSrc(globals.ciam, globals.locale);
      script.onload = ciamReadyDispatch;
      if (!document.querySelector('#gigya-script')) {
        document.body.appendChild(script);
      }
    }
  }, [globals]);

  return (
    <Head>
      {/* ciam/gigya */}
      {globals?.ciam?.enabled && (
        <script
          id="gigya-script"
          src={ciamScriptSrc(globals.ciam, globals.locale)}
          onLoad={ciamReadyDispatch}
        />
      )}
      {/* ciam/gigya end */}

      {/* gtm */}
      {/* {isDev && (
        <Script id="gtm">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=Y51geUNuEXhIBsGRexay-w&gtm_preview=env-189&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmKey}');`}
        </Script>
      )}
      {isQa && (
        <Script id="gtm">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=zoa16TpiGUNPR585n3FnJw&gtm_preview=env-345&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmKey}');`}
        </Script>
      )}
      {isProd && (
        <Script id="gtm">
          {`if(window.navigator.userAgent.indexOf(atob('TGlnaHRob3VzZQ==')) === -1){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${gtmUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');}`}
        </Script>
      )} */}
      {isDev && (
        <script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=Y51geUNuEXhIBsGRexay-w&gtm_preview=env-189&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');`,
          }}
        />
      )}
      {isQa && (
        <script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=zoa16TpiGUNPR585n3FnJw&gtm_preview=env-345&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');`,
          }}
        />
      )}
      {isProd && (
        <script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `if(window.navigator.userAgent.indexOf(atob('TGlnaHRob3VzZQ==')) === -1){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${gtmUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmKey}');}`,
          }}
        />
      )}

      {/* 
        {isDev && (
          <script
            id="gtm"
            src={`${gtmUrl}/gtm.js?id=${gtmKey}&gtm_auth=Y51geUNuEXhIBsGRexay-w&gtm_preview=env-189&gtm_cookies_win=x`}
          />
        )}
        {isQa && (
          <script
            id="gtm"
            src={`${gtmUrl}/gtm.js?id=${gtmKey}&gtm_auth=zoa16TpiGUNPR585n3FnJw&gtm_preview=env-345&gtm_cookies_win=x`}
          />
        )}
        {isProd && <script id="gtm" src={`${gtmUrl}/gtm.js?id=${gtmKey}`} />} */}

      {/* {!isPreview && (
            <noscript>
              <iframe
                src={gtmNoScript}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              />
            </noscript>
          )} */}
      {/* gtm end */}
    </Head>
  );
}
