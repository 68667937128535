// components
import { type InternalLinkProps, InternalLink } from './InternalLink';
import { type MailLinkProps, MailLink } from './MailLink';
import { type PhoneLinkProps, PhoneLink } from './PhoneLink';
import { type ExternalLinkProps, ExternalLink } from './ExternalLink';
import { type CookieLinkProps, CookieLink } from './CookieLink';

// utils
import { isEmpty } from 'utils/is-empty';

function getLinkComponent(linkType) {
  switch (linkType) {
    case 'internal_link':
    case 'internal_button':
    case 'video_category_link':
      return InternalLink;
    case 'cookie_disclaimer':
      return CookieLink;
    case 'mail_link':
      return MailLink;
    default:
      return ExternalLink;
  }
}

interface CmsLinkProps {
  link?: Link;
}

export function CmsLink({
  link,
  children,
  className = '',
  standardFontSize = true,
  hasArrow = false,
  ...props
}: Readonly<
  CmsLinkProps &
    React.PropsWithChildren<
      Omit<
        InternalLinkProps & ExternalLinkProps & MailLinkProps & PhoneLinkProps & CookieLinkProps,
        'content'
      >
    >
>) {
  const { target, type } = link ?? {};

  // return just a div, if there is a missing link
  if (isEmpty(link)) {
    return <div className={className}>{children}</div>;
  }

  let LinkComponent = getLinkComponent(type);

  if (!isEmpty(target) && target?.startsWith('/')) {
    LinkComponent = InternalLink;
  }

  if (!isEmpty(target) && target?.startsWith('http')) {
    LinkComponent = ExternalLink;
  }

  if (!isEmpty(target) && target?.startsWith('mailto')) {
    LinkComponent = MailLink;
  }

  if (!isEmpty(target) && target?.startsWith('tel')) {
    LinkComponent = PhoneLink;
  }

  return (
    <LinkComponent
      content={link}
      className={className}
      standardFontSize={standardFontSize}
      hasArrow={hasArrow}
      {...props}
    >
      {children}
    </LinkComponent>
  );
}
