import { useSelector } from 'react-redux';
import sortBy from 'lodash.sortby';

// components
import { NavItem } from '../switch/nav-item';

// utils
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { useNavOpen } from '../mega-menu-provider';
import { languageSwitchTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useReactKeys } from './use-react-keys';

type LanguageFlyoutProps = {
  items: Language[];
};

export function LanguageSwitch({ items }: Readonly<LanguageFlyoutProps>) {
  const currentLanguage = useSelector(currentLanguageSelector);
  const { setIsNavOpen } = useNavOpen();
  const track = useTracking();
  const itemsWithKeys = useReactKeys(items, ['url', 'labelFull', 'lang']);

  return (
    <div>
      {sortBy(itemsWithKeys, ['hidden', 'labelFull']).map((item) => {
        return (
          <NavItem
            isOption
            leftIconName="checkmark"
            key={item.key}
            selected={item.lang === currentLanguage}
            href={item.url}
            aria-label={item.labelFull}
            onClick={() => {
              track.trackEvent(languageSwitchTrackingAction(item.lang));
              setIsNavOpen(false);
            }}
          >
            {item.labelFull}
          </NavItem>
        );
      })}
    </div>
  );
}
