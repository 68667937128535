import { ThemeProvider } from 'styled-components';
import { defaultTheme as theme } from '@geberit/gdds';

// styles
import styles from './layout.scss';

// components
import MetaData from '../MetaData';
import Footer from 'components/Footer/Footer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

interface CampusLayoutProps extends React.PropsWithChildren {
  metaData?: Partial<MetaData>;
  noErrorBoundary?: boolean;
}

export default function CampusLayout({
  children,
  metaData = {},
  noErrorBoundary = false,
}: Readonly<CampusLayoutProps>) {
  function wrapper(child) {
    if (noErrorBoundary) {
      return child;
    }

    return <ErrorBoundary isCampus>{child}</ErrorBoundary>;
  }

  return wrapper(
    <ThemeProvider key="geberit/theme-provider" theme={theme}>
      <div className={styles.campusLayout}>
        <MetaData {...metaData} />
        <main className={`${styles.campusMain} ${noErrorBoundary ? styles.errorPage : ''}`}>
          {children}
        </main>
        <Footer isCampus />
      </div>
    </ThemeProvider>,
  );
}
